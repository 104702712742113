const initState = {}

const updateReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_DATA_SUCCESS' :
            console.log('update profile success');
            return state;
    
          case 'UPDATE_DATA_ERROR' :
            console.log('update profile error');
            return state;

        default:
            return state;
        }
}
          
export default updateReducer;    
import authReducer from './authReducer';
import scoreReducer from './scoreReducer';
import updateReducer from './updateReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
  auth: authReducer,
  score: scoreReducer,
  name: updateReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer


import React,{useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import firebase from "firebase/app";
import swal from 'sweetalert';
import '../App.css';



function ForgotPassword(){

    const [email,setEmail] = useState('');

    const submitValue = () => {
        firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
            swal("Email Sent !", "Reset your password from link received!", "success");
      })
        .catch((error) => {
       const errorCode = error.code;
       swal("Error code "+error.code, "Try after sometime!", "error");
       // ..
      });
    }

    return(
        <div className="container">
          <div className="container sign-box px-5 py-4 mt-2">
                    <img src="../assets/images/logo.png" className="d-block mx-auto"  width= "100px" height="90px" alt="logo"/>
                    <div className="form-group">
                        <label for="email">Enter email address</label>
                        <input type="email" className="form-control" id='email' onChange={e => setEmail(e.target.value)} aria-describedby="emailHelp" placeholder="Enter email" />
                    </div>
                    <div onClick={submitValue} class="btn btn-primary">Reset Now</div>
              
          </div>
          <div className="row d-flex justify-content-center">
            <div class="m-3">
             <Link to="/" type="button" class="btn btn-info">DashBoard</Link>
            </div>
          </div>
            <footer><p className="text-right mt-auto">copyright &copy; Orbsway Vision - 2021</p></footer>
        </div>
    )
}

export default ForgotPassword;
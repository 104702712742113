import React, { lazy , Suspense} from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import ForgotPassword from './components/forgotPassword';
import Loading from './components/loading';

const Dashboard = lazy(() => import('./components/dashboard'));
const SignIn = lazy(() => import('./components/SignIn')); 
const SignUp = lazy(() => import('./components/SignUp')); 
const PatientPanel = lazy(() => import('./components/PatientPanel')); 
const FlagMessage = lazy(() => import('./components/flagMessage'));
const Doctor = lazy(()=> import('./components/doctor'));
const DoctorPanel = lazy(()=> import('./components/DoctorPanel'));

function App() {
  return (
    <Suspense fallback = {<Loading/>} >
      <BrowserRouter>
        <div className="App">
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route path='/signin' component={SignIn} />
          <Route path='/signup' component={SignUp} />
          <Route path='/verification' component={FlagMessage} />
          <Route path='/panel' component={PatientPanel} />
          <Route path='/doctor' component={Doctor} />
          <Route path='/manage' component={DoctorPanel} />
          <Route path="/forgot-password" component={ForgotPassword}/>
          <Redirect to='/' />
        </Switch>
        </div>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;

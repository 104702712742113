const initState = {}

const scoreReducer = (state = initState, action) => {
    switch (action.type) {
      case 'CREATE_SCORE_SUCCESS':
        console.log('create score success');
        window.location.href = '/panel';
        return state;

      case 'CREATE_SCORE_ERROR':
        console.log('create score error',action.err);
        window.location.href = '/panel';
        return state;
        
      default:
        return state;
    }
  }
  
  export default scoreReducer;
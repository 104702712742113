import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Replace this with your own config details
var config = {
  apiKey: "AIzaSyB1dLxRIgUV_vgTuw_5CT2fx2YeaFH3qjg",
  authDomain: "orbs-1ef98.firebaseapp.com",
  databaseURL: "https://orbs-1ef98.firebaseio.com",
  projectId: "orbs-1ef98",
  storageBucket: "orbs-1ef98.appspot.com",
  messagingSenderId: "307122122128",
  appId: "1:307122122128:web:40b51c502d56425c8b9715",
  measurementId: "G-QGV8ZMDKDB"
};
firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });
const db=firebase.firestore();

export {db};

export default firebase 